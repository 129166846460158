// Testnet Cronos for local test
// export const ContractAddress = '0xf71d138Fc195f40785EEEAf1259C3A0eb48C6549'

// export const defaultChainInfo = {
//   chainId: 338,
//   chainName: 'Cronos Testnet',
//   rpcUrls: ['https://evm-t3.cronos.org'],
//   iconUrls: ['https://cronoscan.com/images/brandassets/logo.jpg?v=22.12.2.0'],
//   blockExplorerUrls: ['https://cronos.crypto.org/explorer/testnet3/'],
//   nativeCurrency: {
//    name: 'Cronos',
//    symbol: 'TCRO',
//    decimals: 18,
//   }
// }

// Mainnet Cronos for live page
export const ContractAddress = "0x8f2836874DC85B81C2CF0421aF593E6E8d5DffA1";
export const RoyaltyContractAddress =
  "0x1099CCf66EefF5c5E78560f244bfE770721564F7";
  export const RoyaltyContractAddressOld =
  "0x6478d6D6dD46B96aeC623DFa609a231be90e394E";

export const defaultChainInfo = {
  chainId: "0x19",
  chainName: "Cronos Mainnet Beta",
  rpcUrls: ["https://evm.cronos.org/"],
  iconUrls: ["https://cronoscan.com/images/brandassets/logo.jpg?v=22.12.2.0"],
  blockExplorerUrls: ["https://cronoscan.com/"],
  nativeCurrency: {
    name: "Cronos",
    symbol: "CRO",
    decimals: 18,
  },
};
